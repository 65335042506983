/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  /*function countCSSRules() {
		var results = '',
        log = '';
		if (!document.styleSheets) {
        return;
		}
		for (var i = 0; i < document.styleSheets.length; i++) {
        countSheet(document.styleSheets[i]);
		}
		function countSheet(sheet) {
        if (sheet && sheet.cssRules) {
		var count = countSelectors(sheet);

		log += '\nFile: ' + (sheet.href ? sheet.href : 'inline <style> tag');
		log += '\nRules: ' + sheet.cssRules.length;
		log += '\nSelectors: ' + count;
		log += '\n--------------------------';
		if (count >= 4096) {
		results += '\n********************************\nWARNING:\n There are ' + count + ' CSS rules in the stylesheet ' + sheet.href + ' - IE will ignore the last ' + (count - 4096) + ' rules!\n';
		}
        }
		}
		function countSelectors(group) {
        var count = 0;
        for (var j = 0, l = group.cssRules.length; j < l; j++) {
		var rule = group.cssRules[j];
		if (rule instanceof CSSImportRule) {
		countSheet(rule.styleSheet);
		}
		if (rule instanceof CSSMediaRule) {
		count += countSelectors(rule);
		}
		if( !rule.selectorText ) {
		continue;
		}
		count += rule.selectorText.split(',').length;
        }
        return count;
		}

		console.log(log);
		console.log(results);

		}
		countCSSRules();
	*/

  // tiny helper function to add breakpoints
  function getGridSize() {
    return window.innerWidth < 480
      ? 1
      : window.innerWidth < 768
      ? 2
      : window.innerWidth < 1600
      ? 2
      : 3;
  }

  function loadMap() {
    var map;
    function bindInfoWindow(
      marker,
      map,
      title,
      desc,
      telephone,
      email,
      web,
      link
    ) {
      var infoWindowVisible = (function () {
        var currentlyVisible = false;
        return function (visible) {
          if (visible !== undefined) {
            currentlyVisible = visible;
          }
          return currentlyVisible;
        };
      })();
      iw = new google.maps.InfoWindow();
      google.maps.event.addListener(marker, "click", function () {
        if (infoWindowVisible()) {
          iw.close();
          infoWindowVisible(false);
        } else {
          var html = "<div><h3>Balbirnie House</h3></div>";
          iw = new google.maps.InfoWindow({ content: html });
          iw.open(map, marker);
          infoWindowVisible(true);
        }
      });
      google.maps.event.addListener(iw, "closeclick", function () {
        infoWindowVisible(false);
      });
    }
    function init() {
      var mapOptions = {
        center: new google.maps.LatLng(56.20968, -3.145741),
        zoom: 15,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.DEFAULT,
        },
        disableDoubleClickZoom: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        },
        scaleControl: true,
        scrollwheel: false,
        panControl: true,
        streetViewControl: true,
        draggable: true,
        overviewMapControl: true,
        overviewMapControlOptions: {
          opened: false,
        },
        mapTypeId: "hybrid",
        styles: [
          {
            featureType: "all",
            elementType: "all",
            stylers: [{ saturation: -100 }, { gamma: 0.5 }],
          },
        ],
      };
      var mapElement = document.getElementById("contactMap");
      var map = new google.maps.Map(mapElement, mapOptions);
      var locations = [
        [
          "Balbirnie House",
          "This is the description",
          "undefined",
          "undefined",
          "/",
          56.20968,
          -3.145741,
          "https://mapbuildr.com/assets/img/markers/ellipse-purple.png",
        ],
      ];
      for (i = 0; i < locations.length; i++) {
        if (locations[i][1] === "undefined") {
          description = "";
        } else {
          description = locations[i][1];
        }
        if (locations[i][2] === "undefined") {
          telephone = "";
        } else {
          telephone = locations[i][2];
        }
        if (locations[i][3] === "undefined") {
          email = "";
        } else {
          email = locations[i][3];
        }
        if (locations[i][4] === "undefined") {
          web = "";
        } else {
          web = locations[i][4];
        }
        if (locations[i][7] === "undefined") {
          markericon = "";
        } else {
          markericon = locations[i][7];
        }
        marker = new google.maps.Marker({
          icon: markericon,
          position: new google.maps.LatLng(locations[i][5], locations[i][6]),
          map: map,
          title: locations[i][0],
          desc: description,
          tel: telephone,
          email: email,
          web: web,
        });
        link = "";
        bindInfoWindow(
          marker,
          map,
          locations[i][0],
          description,
          telephone,
          email,
          web,
          link
        );
      }
      google.maps.event.addListener(map, "click", function (event) {
        this.setOptions({ scrollwheel: true });
      });
      google.maps.event.addListener(map, "mouseout", function (event) {
        this.setOptions({ scrollwheel: false });
      });
    }

    google.maps.event.addDomListener(window, "load", init);
  }
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        new MlPushMenu(
          document.getElementById("mp-menu"),
          document.getElementById("trigger")
        );

        $(".closeTab").click(function () {
          $(".closeTab > i").addClass("spinners");
          setTimeout(function () {
            $(".closeTab > i").removeClass("spinners");
          }, 1000);
        });

        // On Height Change

        var height;
        var available;
        var percentage_of_page;
        var half_screen;

        function write_status() {
          available = $(document).height() - $(window).height();

          quar_percentage_of_page = 0.2;
          percentage_of_page = 0.5;
          thir_percentage_of_page = 0.8;

          quar_screen = available * quar_percentage_of_page;
          half_screen = available * percentage_of_page;
          thir_screen = available * thir_percentage_of_page;

          $("#scroll-val").html(
            height + "/" + available + " - Show at: " + thir_screen
          );
        }

        $(window).scroll(function (e) {
          if ($(window).width() > 768) {
            height = $(window).scrollTop();
            write_status();

            if ($(".gallerySections__1").length) {
              $(".ggWrap").addClass("section1");
            }

            if (
              $(".gallerySections__1").length &&
              $(".gallerySections__2").length
            ) {
              if (height > quar_screen) {
                $(".ggWrap").addClass("section1");
              } else {
                $(".ggWrap").removeClass("section2");
                $(".ggWrap").removeClass("section3");
              }
              if (height > half_screen) {
                $(".ggWrap").removeClass("section1");
                $(".ggWrap").addClass("section2");
              } else {
                $(".ggWrap").removeClass("section2");
              }
            }

            if (
              $(".gallerySections__1").length &&
              $(".gallerySections__2").length &&
              $(".gallerySections__3").length
            ) {
              if (height > quar_screen) {
                $(".ggWrap").addClass("section1");
              } else {
                $(".ggWrap").removeClass("section2");
                $(".ggWrap").removeClass("section3");
              }
              if (height > half_screen) {
                $(".ggWrap").removeClass("section1");
                $(".ggWrap").addClass("section2");
              } else {
                $(".ggWrap").removeClass("section2");
                $(".ggWrap").removeClass("section3");
              }
              if (height > thir_screen) {
                $(".ggWrap").removeClass("section1");
                $(".ggWrap").removeClass("section2");
                $(".ggWrap").addClass("section3");
              } else {
                $(".ggWrap").removeClass("section3");
              }
            }
          } else {
          }
        });

        //                if ($('.gallerySections__1, .gallerySections__2, .gallerySections__3').length) {
        //                    alert('Theres Three');
        //                } else {
        //                    alert('Somethings missing');
        //
        //                }

        var openM = false;
        var openMenu = function () {
          $(".menuMaster > div").addClass("open");
          openM = true;
        };
        var closeMenu = function () {
          $(".menuMaster > div").removeClass("open");
          openM = false;
        };
        $(".menuMaster").click(function (event) {
          event.stopPropagation();
          var toggle = openM ? closeMenu : openMenu;
          toggle();
        });
        $(document).click(function (event) {
          if (!$(event.target).closest(".menuMaster > div").length) {
            closeMenu();
          }
        });

        // store the slider in a local variable
        var $window = $(window),
          myflexslider;

        // Flexslider
        $(".flexslider").flexslider({
          slideshow: true,
          slideshowSpeed: 8000,
          pauseOnAction: true,
          pauseOnHover: true,
          animation: "slide",
          animationLoop: true,
          directionNav: true,
          controlNav: false,
          itemWidth: 300,
          itemMargin: 0,
          minItems: getGridSize(),
          maxItems: getGridSize(),
          touch: true,
          useCSS: false,
        });
        myflexslider = $(".flexslider");

        $(".mainSlider").flexslider({
          slideshow: true,
          slideshowSpeed: 4000,
          pauseOnAction: true,
          pauseOnHover: true,
          animation: "fade",
          animationLoop: true,
          directionNav: true,
          controlNav: false,
          itemWidth: 210,
          itemMargin: 0,
          minItems: 1,
          maxItems: 1,
          touch: true,
          useCSS: false,
        });

        mobileGallerySliders = function (el) {
          var sliderHTML;
          if ($(window).width() < 767) {
            if ($(el).hasClass("mobileFlexSlider") === false) {
              if (
                $(el).find("#contactMap").length < 1 &&
                $(el).find('[class*="flex"]').length < 1 &&
                $("body").hasClass("home") === false
              ) {
                // Not for google maps, home page or if already contains a flexslider!
                $(el).addClass("mobileFlexSlider");
                $(el + ">div").wrap("<li></li>");
                $(el + ">li").wrapAll('<ul class="slides"></ul>');
                $(".mobileFlexSlider").flexslider();
              }
            }
          } else {
            if ($(el).hasClass("mobileFlexSlider")) {
              $(el + ">ul>li").unwrap();
              $(el + ">li>div").unwrap();
              $(el).removeClass("mobileFlexSlider");

              // Flexslider has no destroy method. So remove the markup from the DOM and then re-add it.
              $(el).find('[class*="flex"]').remove(); // Get rid of any flex controls.
              sliderHTML = $(el)[0].outerHTML;
              $(el).before('<div class="hidden sliderHTML"></div>'); // Add a marker so we know where to put our clean html.
              $(el).remove(); // Delete unwanted flexslider element.
              $("div.sliderHTML").after(sliderHTML); // Add our clean HTML.
              $("div.sliderHTML").remove(); // Delete marker div.
            }
          }
        };
        mobileGallerySliders(".ggWrap");

        $window.resize(function () {
          mobileGallerySliders(".ggWrap");
          var gridSize = getGridSize();
          if (myflexslider.length > 0) {
            //flexslider.vars.minItems = gridSize;
            //flexslider.vars.maxItems = gridSize;
            myflexslider.data("flexslider").vars.minItems = gridSize;
            myflexslider.data("flexslider").vars.maxItems = gridSize;
          }
        });

        // Dropdown Menu Stuff
        $("a.dropdown-toggle").click(function () {
          if ($(this).parent().hasClass("open")) {
            window.location.href = $(this).attr("href");
          }
        });

        if ($(window).width() < 991) {
          $("li.menu-item-has-children a").click(function () {
            if ($(this).parent().hasClass("open")) {
              window.location.href = $(this).attr("href");
            }
          });
        }

        menudropdownaction = function () {
          if ($(window).width() < 991) {
            $(".dropdown-toggle").attr("data-toggle", "dropdown");
          }

          if ($(window).width() > 1200) {
            $(".dropdown-toggle").attr("data-toggle", "");
          }
        };

        menudropdownaction();

        $(window).resize(function () {
          menudropdownaction();
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    // About us page, note the change from about-us to about_us.
    contact_us: {
      init: function () {
        loadMap();
      },
    },
    enquiries: {
      init: function () {
        loadMap();
      },
    },
    // Media Wall SDK
    media_wall: {
      init: function () {
        // alert('TEST');
        window.fbAsyncInit = function () {
          FB.init({
            appId: "1112700532099624",
            xfbml: true,
            version: "v2.8",
          });
        };

        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "//connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      },
      finalize: function () {},
    },
    wedding_pack: {
      init: function () {
        document.addEventListener(
          "wpcf7mailsent",
          function (event) {
            var pdf = $(".wedding-pack-pdf").data("pdf-url");
            window.open(pdf, "_blank");
          },
          false
        );
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  $('#wedModal1').on('show.bs.modal', function (e) {
    $('#wedModal1').addClass('modal-flex');
  })
  $('#wedModal1').on('hide.bs.modal', function (e) {
    $('#wedModal1').removeClass('modal-flex');
  })
  $('#wedModal2').on('show.bs.modal', function (e) {
    $('#wedModal2').addClass('modal-flex');
  })
  $('#wedModal2').on('hide.bs.modal', function (e) {
    $('#wedModal2').removeClass('modal-flex');
  })

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
